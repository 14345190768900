import { render, staticRenderFns } from "./ArtifactSnapper.vue?vue&type=template&id=cdb78f3c&scoped=true&"
import script from "./ArtifactSnapper.vue?vue&type=script&lang=js&"
export * from "./ArtifactSnapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdb78f3c",
  null
  
)

export default component.exports